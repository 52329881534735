/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

jQuery.noConflict();

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Infogrupa = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                
                var $javaGroup = $('#java-group');

                if ($javaGroup.length > 0) {
                    var number = parseInt($javaGroup.val());

                    $('.trade-list-button ').eq(number).removeClass('collapsed');
                    $('.trade-list-content ').eq(number).addClass('show');
                }
                
                $('.go-up').on('click', function(event) {
                    var body = $("html, body");
                    body.stop().animate({scrollTop:0}, 500, 'swing', function() { 
                    });
                });

                $('#popup_form').submit(function( event ) {
                    event.preventDefault();

                    var ajaxData = new FormData();

                    ajaxData.append('Nazwa firmy', $('input[name="popup_nazwa_firmy"]').val());   
                    ajaxData.append('Kod pocztowy', $('input[name="popup_kod"]').val());
                    ajaxData.append('Miejscowość', $('input[name="popup_miejscowosc"]').val());
                    ajaxData.append('Mail', $('input[name="popup_ulica"]').val());
                    ajaxData.append('Dane osobowe', $('input[name="popup_dane_osobowe"]').val());
                    ajaxData.append('Kontakt', $('input[name="popup_dane_kontaktowe"]').val());
                    ajaxData.append('Posesja', $('input[name="popup_posesja"]').val());
                    ajaxData.append('Lokal', $('input[name="popup_lokal"]').val());
                    
                    $.ajax({
                        url: 'main/thanks',
                        cache: false,
                        contentType: false,
                        processData: false,
                        // async: false,
                        data: ajaxData,
                        type: "POST",
                        dataType: "html",
                        success: function (data) {
                          if (data != null) {

                            $('#message').html('').html(data).removeClass('d-none');
                                                    
                            $('#popup_form')[0].reset();
                        

                          } else {

                          }
                        },
                        error: function (xhr, status) {
                            alert("Sorry, there was a problem!");
                        },
                        complete: function (xhr, status, data) {
                            //$('#html_data').slideDown('slow')
                        }
                    });
                });

                // $('.company-gallery')
                $(".company-gallery").owlCarousel({
                    lazyLoad: true,
                    nav: true,
                    dots: false,
                    loop: true,
                    // navText: ["<span class='slider-prev-icon'></span>", "<span class='slider-next-icon'></span>"],
                    autoplay:false,
                    autoplayTimeout: 5000,
                    autoplayHoverPause:true,
                    smartSpeed: 500,
                    autoplaySpeed: 1000,
                    responsiveClass:true,
                    mouseDrag: true,
                    responsive : {
                        // breakpoint from 0 up
                        0 : {
                            items : 1
                        },
                        480 : {
                            items : 2
                        },
                        768 : {
                            items : 3
                        },
                        992 : {
                            items : 5
                        },
                    }
                  // dotsContainer: '.slider-nav-container'
                });

                $(".banery").owlCarousel({
                    items: 1,
                    lazyLoad: true,
                    nav: false,
                    dots: true,
                    loop: true,
                    // navText: ["<span class='slider-prev-icon'></span>", "<span class='slider-next-icon'></span>"],
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause:true,
                    smartSpeed: 500,
                    autoplaySpeed: 1000,
                    responsiveClass:true,
                    mouseDrag: true
                  // dotsContainer: '.slider-nav-container'
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'o_nas': {
            init: function() {

                var time = 4;
                var $bar,
                    $slick,
                    isPause,
                    tick,
                    percentTime;

                $slick = $('.rekomendacje');
                var $status = $('.pagingInfo')
                $slick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
                    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $status.text(i + ' z ' + slick.slideCount);
                });
                $slick.slick({
                    slide: 'p',
                    draggable: true,
                    adaptiveHeight: false,
                    dots: false,
                    mobileFirst: true,
                    pauseOnDotsHover: true,
                    appendArrows: '.slicknav',
                    prevArrow: $('.prev-rec'),
                    nextArrow: $('.next-rec'),
                });



                $bar = $('.slider-progress .progress');

                $('.rekomendacje-cont').on({
                    mouseenter: function() {
                        isPause = true;
                    },
                    mouseleave: function() {
                        isPause = false;
                    }
                })

                function startProgressbar() {
                    resetProgressbar();
                    percentTime = 0;
                    isPause = false;
                    tick = setInterval(interval, 10);
                }

                function interval() {
                    if (isPause === false) {
                        percentTime += 1 / (time + 0.1);
                        $bar.css({
                            width: percentTime + "%"
                        });
                        if (percentTime >= 100) {
                            $slick.slick('slickNext');
                        }
                    }
                }


                function resetProgressbar() {
                    $bar.css({
                        width: 0 + '%'
                    });
                    clearTimeout(tick);
                }

                startProgressbar();

                $slick.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                    startProgressbar();
                })
            }
        },
        // About us page, note the change from about-us to about_us.
        'historie_z_happy_endem': {
            init: function() {

                $('.newshe-item').each(function(index, el) {
                    var height = $(this).find('.left-section').outerHeight();
                    $(this).find('.right-section .the-content').css('height', height);
                });


            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Infogrupa;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.